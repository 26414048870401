<template>
    <div>
        <modal name="my-first-modal"> This is my first modal </modal>
        <div class="container-inner" :class="{ 'empty-page': food_count === 0 }">
            <div class="empty" v-if="food_count === 0"><span>Empty</span></div>
            <div class="menus" v-else>
                <div class="list-item" v-for="(item, index) in cart_food_list" :key="index">
                    <div class="row">
                        <div class="col-5 col-sm-3 text-right pr-1">
                            <div class="item-img" v-lazy:background-image="getItemImage(item)"></div>
                        </div>
                        <div class="col-7 col-sm-9 li-c">
                            <div class="row">
                                <div class="col-6 pr-0">
                                    <h3 class="pt-1 color-black">
                                        {{ translate('item', item.fname) }}
                                    </h3>
                                </div>
                                <div class="col-6 text-right px-0">
                                    <div class="pu pt-2 text-truncate">{{ getUserName(item.uname.split('@')[0]) }}</div>
                                </div>
                            </div>
                            <div class="note-text mt-sm-1 text-truncate" v-if="item.note">
                                <span> {{ $t('cart.note') }}: {{ item.note }}</span>
                            </div>
                            <div class="addons my-1" v-if="item.feature_name">
                                {{ translate('feature_value', item.feature_name) }}
                            </div>
                            <div class="addons" v-if="item.addon_names">
                                <div
                                    v-for="(addon_name_item, addon_index) in item.addon_names.split('@@')"
                                    v-bind:key="addon_name_item"
                                    class="border-bottom"
                                >
                                    <div
                                        v-for="(item_name, item_index) in addon_name_item.split('|')"
                                        :key="item_index"
                                        class="cat"
                                    >
                                        <div class="d-flex justify-content-between">
                                            <span class="d-inline-block text-truncate" style="width: 80%">
                                                {{ getItemText(item, item_name, item_index, addon_index) }}
                                            </span>
                                            <span class="d-inline-block text-left">
                                                ${{ getItemAddonNum(item, item_index, addon_index) }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item-bottom mt-2">
                                <div class="row">
                                    <div class="col-8">
                                        <h2 class="d-flex justify-content-between">
                                            <span style="color: #9b9b9b"> ${{ item.fprice }}</span>
                                        </h2>
                                        <div class="quantity">x {{ item.fcount }}</div>
                                        <div class="sum">
                                            {{ $t('cart.sum') }}
                                            <span class="num"> ${{ getItemSumPrice(item) }} </span>
                                        </div>
                                    </div>
                                    <div class="col-4 text-right pl-0 action">
                                        <a class="edit" @click="edit_food(item)"></a>
                                        <img
                                            class="remove"
                                            src="@/assets/img/buy_cart_remove.png"
                                            @click.stop="remove(item)"
                                        />
                                    </div>
                                    <!-- <div>
                                        <a class="delete" @click="remove(item)"></a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-actions submit_order">
        </div>

        <a
            class="single_button_fixed"
            v-if="food_count > 0"
            :class="{ disabled: cart_price < 0 || !can_place_order || placeOrderTimer }"
            data-bee="module_id=button_click&item_id=submit_order"
            @click="handleCTABn"
        >
            <img v-if="loading" class="loading" src="@/assets/images/loading.svg" alt="" />
            <span v-else>
                {{ placeOrderTxt }}
                <template v-if="store_data.cart_submit_button_show_subtotal">
                    (${{(is_vip ? cart_data.total_price_vip : cart_data.total_price) || 0}})
                </template>
            </span>
        </a>
        <FoodDetailLayerCart
            v-bind:detail_data="detail_data"
            v-if="show_cart_detail"
            v-bind:fcount="target_food_count"
            v-bind:close_fun="close_layer"
            from="cart"
            v-bind:is_vip="is_vip"
        />
        <ReviewLayer />
    </div>
</template>

<style lang="scss" scoped>
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.loading {
    position: static;
    background-color: transparent;
    color: white;
    width: 30px;
    height: 30px;
    animation: rotate 2s infinite;
    animation-timing-function: linear;
}
/* .delete {
    background: url('../assets/img/minus.svg') no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 100px;
    display: inline-block;
} */
.disabled {
    background-color: grey !important;
}
.float-cart {
    position: fixed;
    right: 0.75rem;
    bottom: 6rem;
    // color: #000;
    color: var(--themeColorFont);
    font-size: 0.6rem;
    width: 55px;
    height: 55px;
    line-height: 17px;
    background: var(--themeColor);
    border-radius: 50%;
    box-shadow: 2px 2px 3px #999;
    cursor: pointer;
    font-family: $boldFont;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .remove {
        width: 30px;
        margin-top: 10px;
    }
}
.sum {
    color: var(--themeColor);
    font-weight: bold;
    .num {
        font-weight: bold;
    }
}
.quantity {
    color: black;
    font-weight: bold;
    font-size: 16px;
}
</style>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import FoodDetailLayerCart from '../components/FoodDetailLayerCart';
import { createNamespacedHelpers } from 'vuex';
import ReviewLayer from '../components/ReviewLayer.vue';
import validates from '@/service/validates.js';
const App = createNamespacedHelpers('app');

Vue.component(FoodDetailLayerCart.name, FoodDetailLayerCart);
Vue.component(ReviewLayer.name, ReviewLayer);

export default {
    name: 'cart',
    data() {
        return {
            loading: false,
            reminded: false,
            placeOrderTxt: '',
            placeOrderTimer: null,
            leftMinutes: 0,
            submit_text: 'Place orders',
            detail_data: {},
            target_food_count: 0,
            choose_count: this.target_food_count,
            shared_tname: this.$store.state.app.shared_tname,
            content_height: document.body.clientHeight - 180,
            note: ''
        };
    },
    created() {
        let _title = this.$t('cart.cart');
        if (this.food_count > 0) {
            _title = _title + '(' + this.food_count + ')';
        }
        this.set_app_title(_title);
    },
    beforeDestroy() {
        //make sure it's closed
        this.doToggleDetail(false);
        this.toggleSubmitting(false);
    },
    watch: {
        '$store.state.app.store_data': {
            immediate: true,
            handler: function (val, oldVal) {
                if (val.order_interval_enable) {
                    this.ifInOrderInterval();
                }
            }
        },
        language: {
            immediate: true,
            handler: function (val, oldVal) {
                this.placeOrderTxt = this.$t('cart.submit_order')
            }
        },
        food_count() {
            let _title = this.$t('cart.cart')
            if (this.food_count > 0) {
                _title = _title + '(' + this.food_count + ')';
            }

            this.set_app_title(_title);
        }
    },
    computed: {
        total_price() {
            let extra_price = 0;
            if (this.detail_data?.addon_prices) {
                const addon_prices = this.detail_data.addon_prices.split('@@');
                const addon_counts = this.detail_data.addon_counts.split('@@');

                for (let i = 0, len = addon_prices.length; i < len; i++) {
                    const items = addon_prices[i].split('|');
                    const prices = addon_counts[i].split('|');
                    for (let j = 0; j < items.length; j++) {
                        extra_price = extra_price + Number(items[j]) * Number(prices[j]);
                    }
                }
            }

            if (this.choose_count > 0) {
                let _price =
                    this.is_vip && this.detail_data.fprice_vip ? this.detail_data.fprice_vip : this.detail_data.fprice;
                return ((Number(_price) + extra_price) * this.choose_count).toFixed(2);
            }
            return 0;
        },
        ...mapState({
            show_cart_detail: (state) => state.app.show_cart_detail,
            tid: (state) => state.app.shared_tid,
            shared_cart_id: (state) => state.app.shared_cart_id,
            food_dict: (state) => state.menu.food_dict,
            isSubmitting: 'app/isSubmitting',
            cart_data: (state) => state.app.cart_data,
        }),
        ...App.mapState(['store_data']),
        ...mapGetters({
            translate: 'app/translate',
            is_vip: 'app/get_if_is_vip',
            food_count: 'app/get_food_count',
            cart_food_list: 'app/get_cart_food_list',
            cart_price: 'app/get_cart_price',
            cart_price_vip: 'app/get_cart_price_vip',
            cart_food_dict: 'app/get_cart_food_dict',
            shared_oid: 'app/get_shared_oid',
            msg: 'app/get_app_msg',
            can_place_order: 'app/get_if_can_place_order',
            language: 'app/get_language',
            phone_num: 'app/get_phone_num'
        }),
        remark() {
            return this.$store.state.app.cart_data.remark;
        }
    },
    methods: {
        ...mapActions({
            edit_cart_food_count: 'app/edit_cart_food_count',
            submit_cart: 'app/submit_cart',
            doToggleDetail: 'app/doToggleDetail',
            set_msg: 'app/setToast',
            getDailyLimit: 'menu/getDailyLimit',
            toggleSubmitting: 'app/toggleSubmitting',
            ifBillCreated: 'app/ifBillCreated',
            getOrderTimeByTid: 'app/getOrderTimeByTid',
            get_pre_order_status: 'app/get_pre_order_status',
            initData: 'sharedorderdetail/initData'
        }),
        ...mapMutations({
            set_app_title: 'app/APP_SET_APP_TITLE'
        }),
        ifInOrderInterval() {
            const storeData = this.$store.state.app.store_data;
            if (this.$store.state.app.type === 'preorder') {
                return;
            }
            // eslint-disable-next-line
            return new Promise(async (res, rej) => {
                if (storeData.order_interval_enable) {
                    const createTime = await this.getOrderTimeByTid();
                    let lastPlaceOrder = parseInt(createTime);
                    const { order_interval } = storeData;
                    if (lastPlaceOrder + order_interval * 60 * 1000 <= Date.now()) {
                        res({
                            order_interval_enable: true,
                            inInterval: false
                        });
                    } else {
                        // 设置定时器
                        const timmer = () => {
                            const left = lastPlaceOrder + order_interval * 60 * 1000 - Date.now();
                            let minutes = parseInt(left / (1000 * 60));
                            let seconds = parseInt((left % (1000 * 60)) / 1000);
                            this.leftMinutes = minutes;
                            this.placeOrderTxt = this.$t('cart.place_order_timmer', {minutes, seconds})
                            if (minutes <= 0 && seconds <= 0 && this.placeOrderTimer) {
                                this.placeOrderTimer = null;
                                this.placeOrderTxt = this.$t('cart.place_order');
                            } else {
                                this.placeOrderTimer = setTimeout(timmer, 1000);
                            }
                        };
                        if (!this.placeOrderTimer) {
                            timmer();
                        }
                        res({
                            order_interval_enable: true,
                            inInterval: true
                        });
                    }
                } else {
                    res({
                        order_interval_enable: false
                    });
                }
            });
        },
        remove(_data, { silence = false, withoutLock = false } = {}) {
            if (!_data.fid) {
                _data = _data.food;
            }
            const _remove = () => {
                let _food_data = JSON.parse(JSON.stringify(_data));
                _food_data.fcount = this.choose_count;

                let save_data = {
                    cartid: this.$store.state.app.shared_cart_id,
                    tid: this.$store.state.app.shared_tid,
                    uid: this.$store.state.app.uid,
                    uname: _food_data.uname,
                    router: this.$router,
                    type: 'set',
                    is_vip: this.is_vip,
                    fid: _food_data.fid,
                    fname: _food_data.fname,
                    fprice: _food_data.fprice,
                    fprice_vip: _food_data.fprice_vip || '',
                    feature_name: _food_data.feature_name,
                    addon_names: _food_data.addon_names,
                    addon_prices: _food_data.addon_prices,
                    total_price: this.total_price,
                    set_count: 0,
                    note: _food_data.note,
                    addon_counts: _food_data.addon_counts,

                    silence,
                    withoutLock
                };

                this.edit_cart_food_count(save_data);
            };

            if (silence) {
                _remove();
                return;
            }

            const tip = this.$t('cart.confirm_delete');
            this.$modal.show('dialog', {
                title: '',
                text: tip,
                buttons: [
                    {
                        title: this.$t('common.back'),
                        handler: () => {
                            this.$modal.hide('dialog');
                        }
                    },
                    {
                        title: this.$t('common.confirm'),
                        handler: () => {
                            this.$modal.hide('dialog');
                            _remove();
                        }
                    }
                ]
            });
        },
        getUserName(name) {
            if (!name) {
                return `GUEST`;
            }
            return name.toLowerCase().replace('guest', '#');
        },
        getItemImage(item) {
            return item.fpic || 'https://wx2.sinaimg.cn/bmiddle/63547bb9ly1fw87tl1eauj205k05kaa1.jpg';
        },

        getItemAddonNum(item, item_index, addon_index) {
            return (
                item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                item.addon_prices.split('@@')[addon_index].split('|')[item_index]
            ).toFixed(2);
        },

        getItemText(item, item_name, item_index, addon_index) {
            const addOnNum = item.addon_counts.split('@@')[addon_index].split('|')[item_index];
            return `${this.translate('addon_value', item_name)} x ${addOnNum}`;
        },

        getItemPrice(item) {
            return Number((item.fprice * item.fcount).toFixed(2));
        },

        getItemSumPrice(item) {
            let extra_price = 0;
            if (item.addon_prices) {
                const addon_prices = item.addon_prices.split('@@');
                const addon_counts = item.addon_counts.split('@@');

                for (let i = 0, len = addon_prices.length; i < len; i++) {
                    const items = addon_prices[i].split('|');
                    const prices = addon_counts[i].split('|');
                    for (let j = 0; j < items.length; j++) {
                        extra_price = extra_price + Number(items[j]) * Number(prices[j]);
                    }
                }
            }
            let price = (Number(item.fprice) + extra_price) * item.fcount;
            return price.toFixed(2);
        },

        async handleCTABn() {
            if (this.loading) return;

            const oid = this.$store.state.app.shared_oid.split('-')[0];

            const billCreated = await this.ifBillCreated(oid);
            if (billCreated === undefined) return;
            if (billCreated) {
                this.set_msg({
                    msg: this.$t('cart.bill_created')
                });
                return;
            }

            if (this.food_count > 0 && this.can_place_order) {
                const storeData = this.$store.state.app.store_data;

                const checkCountLimit = () => {
                    return new Promise((res, rej) => {
                        if (storeData.item_count_limit_enable === 1) {
                            const maxItem = storeData.item_count_limit;
                            let sum = 0;
                            sum =
                                this.cart_food_list?.reduce((sum, item) => {
                                    return sum + item.fcount;
                                }, sum) || 0;
                            if (sum > maxItem) {
                                // const engTip =
                                //     'According to the restaurant policy, to reduce food waste, guests can submit no more than ' +
                                //     maxItem +
                                //     ' items for each order. Thank you for your understanding';
                                // const chTip =
                                //     '根据餐厅规定，为了减少⻝物浪费，在每次提交订单时，客⼈提交的餐品不能超过' +
                                //     maxItem +
                                //     '个。谢谢您的理解与⽀ 持。';
                                this.$modal.show('dialog', {
                                    title: '',
                                    text: this.$t('cart.max_count_perorder', {max: maxItem}),
                                    buttons: [
                                        {
                                            title: this.$t('common.back'),
                                            handler: () => {
                                                rej();
                                                this.$modal.hide('dialog');
                                            }
                                        },
                                        {
                                            title: this.$t('common.confirm'),
                                            handler: () => {
                                                rej();
                                                this.$modal.hide('dialog');
                                            }
                                        }
                                    ]
                                });
                            } else {
                                res();
                            }
                        } else {
                            res();
                        }
                    });
                };

                const checkOrderInterval = () => {
                    const { order_interval } = storeData;
                    // eslint-disable-next-line
                    return new Promise(async (res, rej) => {
                        if (this.$store.state.app.type === 'preorder') {
                            res();
                            return;
                        }
                        const result = await this.ifInOrderInterval();
                        if (result.inInterval) {
                            const title = this.$t('cart.please_wait');
                            const content =
                                this.$t('cart.order_interval', {order_interval, leftMinutes: this.leftMinutes})
                            this.$modal.show('dialog', {
                                title,
                                text: content,
                                buttons: [
                                    {
                                        title: this.$t('common.back'),
                                        handler: () => {
                                            this.$modal.hide('dialog');
                                            rej();
                                        }
                                    },
                                    {
                                        title: this.$t('common.confirm'),
                                        handler: () => {
                                            this.$modal.hide('dialog');
                                            rej();
                                        }
                                    }
                                ]
                            });
                        } else {
                            if (!result.order_interval_enable) {
                                res();
                                return;
                            }
                            const content =
                                this.reminded ? this.$t('cart.order_interval_reminded', {order_interval}) : this.$t('cart.order_interval_noReminded', {order_interval})
                            
                            this.reminded = true;
                            this.$modal.show('dialog', {
                                title: '',
                                text: content,
                                buttons: [
                                    {
                                        title: this.$t('common.back'),
                                        handler: () => {
                                            this.$modal.hide('dialog');
                                            rej();
                                        }
                                    },
                                    {
                                        title: this.$t('common.confirm'),
                                        handler: () => {
                                            this.$modal.hide('dialog');
                                            setTimeout(() => {
                                                res();
                                            }, 1000);
                                        }
                                    }
                                ]
                            });
                        }
                    });
                };

                const checkPreorder = () => {
                    // eslint-disable-next-line
                    return new Promise(async (res, rej) => {
                        if (this.$store.state.app.type == 'preorder') {
                            const maxOrderSwitch = !!storeData.can_order_limit_enable;
                            let maxOrder = storeData.can_order_limit;
                            const oid = this.shared_oid.split('-')[0];
                            const cartid = this.shared_cart_id;
                            await this.initData({ oid, cartid });
                            const foods = this.$store.state.sharedorderdetail.foods;
                            if (!maxOrderSwitch) {
                                this.get_pre_order_status({
                                    sid: this.$store.state.app.sid,
                                    pre_tid: this.$store.state.app.shared_tid,
                                    cb: () => {
                                        this.do_submit();
                                    }
                                });
                                rej();
                                return;
                            }
                            if (maxOrder > foods?.length) {
                                const dialog_text = this.reminded ? this.$t('cart.preorder_max_order_reminded', {maxOrder}) : this.$t('cart.preorder_max_order_noReminded', {maxOrder})

                                this.reminded = true;

                                this.$modal.show('dialog', {
                                    title: '',
                                    text: dialog_text,
                                    buttons: [
                                        {
                                            title: this.$t('common.back'),
                                            handler: () => {
                                                rej();
                                                this.$modal.hide('dialog');
                                                this.toggleSubmitting(false);
                                            }
                                        },
                                        {
                                            title: this.$t('common.confirm'),
                                            handler: () => {
                                                rej();
                                                this.$modal.hide('dialog');
                                                this.get_pre_order_status({
                                                    sid: this.$store.state.app.sid,
                                                    pre_tid: this.$store.state.app.shared_tid,
                                                    cb: () => {
                                                        this.reminded = true;
                                                        this.do_submit();
                                                    }
                                                });
                                            }
                                        }
                                    ]
                                });
                            } else {
                                this.$modal.show('dialog', {
                                    title: '',
                                    text: this.$t('cart.preorder_submitted', {count: foods?.length}),
                                    buttons: [
                                        {
                                            title: this.$t('common.back'),
                                            handler: () => {
                                                rej();
                                                this.$modal.hide('dialog');
                                            }
                                        },
                                        {
                                            title: this.$t('common.confirm'),
                                            handler: () => {
                                                rej();
                                                this.$modal.hide('dialog');
                                            }
                                        }
                                    ]
                                });
                            }
                        } else {
                            res();
                        }
                    });
                };

                const checkDailyLimit = () => {
                    // eslint-disable-next-line
                    return new Promise(async (res, rej) => {
                        const foods = this.cart_food_list;
                        const limitList = await this.getDailyLimit(foods.map((food) => food.fid).join(','));
                        const left = [];
                        const saleOut = [];
                        const foodsChecked = [];
                        foods.forEach((food) => {
                            const id = food.fid;

                            if (foodsChecked.includes(id)) {
                                return;
                            }
                            foodsChecked.push(id);

                            const foodLimit = limitList.filter((item) => item.fid === id)[0];
                            const daily_limit = foodLimit?.daily_limit;
                            if (!daily_limit) return;
                            const count = this.cart_food_dict[id].mix_fcount;
                            const daily_sales_count = foodLimit.daily_sales_count;
                            if (daily_limit <= daily_sales_count) {
                                saleOut.push(food);
                            } else if (daily_limit - daily_sales_count < count) {
                                left.push({
                                    left: daily_limit - daily_sales_count,
                                    ...food
                                });
                            }
                        });
                        let tip = '';
                        if (left.length > 0) {
                            left.forEach((item) => {
                                let tipLeft =
                                    this.$t('cart.food_left', {fname: this.translate('item', item.fname), left: item.left})
                                tipLeft += '<br /><br />';
                                tip += tipLeft;
                            });
                        }
                        if (saleOut.length > 0) {
                            const names = saleOut
                                .map((item) => {
                                    return this.translate('item', item.fname)
                                })
                                .join(',');
                            tip +=
                                '[' +
                                names +
                                ']' +
                                this.$t('cart.sold_out')
                        }
                        if (tip !== '') {
                            this.$modal.show('dialog', {
                                text: tip,
                                buttons: [
                                    {
                                        title: this.$t('common.back'),
                                        handler: () => {
                                            this.$modal.hide('dialog');
                                            saleOut.forEach((food) => {
                                                this.remove(food, { silence: true, withoutLock: true });
                                            });
                                        }
                                    },
                                    {
                                        title: this.$t('common.confirm'),
                                        handler: () => {
                                            this.$modal.hide('dialog');
                                            saleOut.forEach((food) => {
                                                this.remove(food, { silence: true, withoutLock: true });
                                            });
                                        }
                                    }
                                ]
                            });
                            rej();
                        } else {
                            res();
                        }
                    });
                };

                this.loading = true;

                try {
                    await checkDailyLimit();
                    await checkCountLimit();
                    await checkOrderInterval();
                    await checkPreorder();
                } finally {
                    this.loading = false;
                }

                this.do_submit();
            } else {
                this.can_not_commit();
            }
        },
        can_not_commit() {
            if (this.can_place_order == 0) {
                this.set_msg({
                    msg: 'Pre-order is not available now'
                });
            }
        },
        edit_food(_data) {
            this.detail_data = _data;
            this.doToggleDetail(true);
            this.target_food_count = _data.fcount || 0;
        },
        close_layer(e) {
            this.doToggleDetail(false);
            this.detail_data = {};
            this.target_food_count = 0;
        },
        go_to_menu(e) {
            this.$router.push({ path: '/menu' });
        },
        go_to_order(e) {
            this.$router.push({ path: '/sub/sharedorderdetail' });
        },
        do_submit() {
            if (this.isSubmitting) {
                return;
            }
            this.toggleSubmitting(true);

            let cartid = this.$store.state.app.shared_cart_id;

            let temp_food_list = this.cart_food_list;

            let all_foods = [];
            let all_foods_count = [];
            let all_user_names = [];
            let all_food_features = [];
            let all_addons_names = [];
            let all_addons_prices = [];
            let all_addons_counts = [];
            let all_notes = [];
            let ototal = temp_food_list.length;
            let opic = '';
            let odesc = '';

            for (let i = 0, len = temp_food_list.length; i < len; i++) {
                all_foods.push(temp_food_list[i].fid);
                all_foods_count.push(temp_food_list[i].fcount);
                all_user_names.push(temp_food_list[i].uname);
                all_food_features.push(temp_food_list[i].feature_name || '');
                all_addons_names.push(temp_food_list[i].addon_names || '');
                all_addons_prices.push(temp_food_list[i].addon_prices || '');
                all_addons_counts.push(temp_food_list[i].addon_counts || '');
                all_notes.push(temp_food_list[i].note || '');

                if (i == 0) {
                    opic = temp_food_list[i].fpic;
                    odesc = temp_food_list[i].fname;
                }

                if (i == 1) {
                    odesc = odesc + '、' + temp_food_list[i].fname;
                }
            }

            if (ototal > 2) {
                odesc = odesc + ' and ' + ototal + ' other foods';
            }

            const tax = (this.cart_price * this.store_data.tax).toFixed(2);
            let service_fee = this.store_data.service_fee_enable
                ? (this.cart_price * this.store_data.service_fee_rate).toFixed(2)
                : 0;
            let processing_fee = this.store_data.processing_fee_enable
                ? (this.cart_price * this.store_data.processing_fee_rate).toFixed(2)
                : 0;
            let charge_detail = {
                price: this.cart_price,
                price_vip: this.cart_price_vip,
                tax,
                service_fee,
                processing_fee,
                delivery_fee: '',
                tip: '',
                price_pickup: '',
                new_discount: '',
                full_coupon_discount: ''
            };

            if (odesc.length > 100) {
                odesc = odesc.substr(0, 100);
            }

            if (isNaN(this.cart_price)) {
                this.set_msg({
                    msg: 'Cart price wrong, please reload the page'
                });
                return;
            }

            const foods_daily_limit = {};
            all_foods.forEach((fid) => {
                // if (this.food_dict[fid].daily_limit > 0) {
                foods_daily_limit[fid] = {
                    count: this.cart_food_dict[fid].mix_fcount
                };
                // }
            });

            let post_data = {
                oid: this.$store.state.app.shared_oid || '',
                uid: this.$store.state.app.uid,
                sid: this.$store.state.app.sid,
                oprice: parseFloat(this.cart_price) + parseFloat(tax) + parseFloat(service_fee) + parseFloat(processing_fee),
                oprice_vip: this.cart_price_vip,
                ofoods: all_foods.join(','),
                foods_daily_limit: JSON.stringify(foods_daily_limit),
                ocount: all_foods_count.join(','),
                ouser_names: all_user_names.join(','),
                ofood_features: all_food_features.join(','),
                ofood_notes: all_notes.join('-_-'),
                ofood_addon_names: all_addons_names.join('-_-'),
                ofood_addon_prices: all_addons_prices.join('-_-'),
                ofood_addon_counts: all_addons_counts.join('-_-'),
                otype: this.$store.state.app.type || 'scan',
                opay_type: '',
                omessage: this.remark,
                ostatus: 0,
                name: '',
                address: '',
                tel: this.phone_num,
                odesc: odesc,
                ototal: ototal,
                opic: opic,
                user_info: '',
                is_shared: 0,
                cartid: '',
                tid: this.$store.state.app.shared_tid,
                tname: this.shared_tname,
                tstatus: this.$store.state.app.shared_tstatus,
                is_vip: this.is_vip,
                charge_detail: JSON.stringify(charge_detail),
                router: this.$router,
                extra_fee_detail: JSON.stringify({
                    tax: this.store_data.tax,
                    service_fee: service_fee ? this.store_data.service_fee_rate : 0,
                    processing_fee: processing_fee ? this.store_data.processing_fee_rate : 0,
                })
            };

            // 提交到共享订单
            if (cartid != '') {
                post_data.cartid = cartid;
                post_data.is_shared = 1;
            }
            if (this.reminded) {
                this.submit_cart(post_data);
                return;
            }

            const title = this.$t('cart.if_submit_order');
            const content = this.$t('cart.add_all_before_submit');
            this.$modal.show('dialog', {
                title,
                text: content,
                buttons: [
                    {
                        title: this.$t('common.back'),
                        handler: () => {
                            this.$modal.hide('dialog');
                            this.toggleSubmitting(false);
                        }
                    },
                    {
                        title: this.$t('common.confirm'),
                        handler: () => {
                            this.submit_cart(post_data);
                            this.$modal.hide('dialog');
                        }
                    }
                ]
            });
        }
    }
};
</script>
