<template>
    <div class="review_layer" v-if="show_review_layer">
        <div class="review_layer_copy" @click="hide"></div>
        <div class="review_inner">
            <div class="review_msg" v-if="showMsg">
                <span>{{ $t('footer_buttons.ratePlease') }}</span>
            </div>
            <div class="review_title">{{ $t('footer_buttons.yourExperience') }}</div>
            <div class="review_star">
                <div class="star_item"
                    v-for="(starItem, index) in starAry"
                    :key="index"
                >
                    <div class="star_radio">
                        <input
                            :id="'review_star' + starItem.value"
                            name="review_star"
                            type="radio"
                            :value="starItem.value"
                            @click="changeStar(starItem.value)"
                        />
                        <span :class="starItem.show ? 'radio_icon radio_curr' : 'radio_icon'"></span>
                    </div>
                    <label :for="'review_star' + starItem.value">{{ starItem.value }}</label>
                </div>
            </div>
            <div class="review_input">
                <textarea
                    :placeholder="$t('footer_buttons.tellUs')"
                    rows="6"
                    v-model="experience"
                    cols="40"
                ></textarea>
            </div>
            <div class="review_btn">
                <button @click="hide">{{ $t('footer_buttons.cancel') }}</button>
                <button class="submit_btn" @click="confirm">{{ $t('footer_buttons.submit') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';

const App = createNamespacedHelpers('app');
export default {
    name: 'ReviewLayer',
    props: {
        // rateData: Object,
        // rateSumbit: Function,
    },
    data() {
        return {
            showMsg: false,
            experience: '',
            starAry: [
                { show: false, value: 1 },
                { show: false, value: 2 },
                { show: false, value: 3 },
                { show: false, value: 4 },
                { show: false, value: 5 }
            ],
            starValue: 0,
        };
    },
    computed: {
        ...App.mapGetters({
            show_review_layer: 'get_show_review_layer',
            phone_num: 'get_phone_num',
            shared_tname: 'get_shared_tname',
            shared_oid: 'get_shared_oid',
        })
    },
    methods: {
        ...mapActions({
            set_msg: 'app/setToast',
        }),
        ...App.mapMutations({
            toggleReviewLayer: 'TOGGLE_REVIEW_LAYER'
        }),
        hide() {
            this.toggleReviewLayer();
            this.clearReviewData();
        },
        confirm() {
            if (this.starValue <= 0) {
                this.showMsg = true;
                setTimeout(() => {
                    this.showMsg = false;
                }, 2000);
                // this.set_msg({
                //     msg: this.$t('footer_buttons.ratePlease')
                // });
                return;
            }

            let survey_data = {
                sid: this.$store.state.app.sid,
                oid: this.shared_oid,
                tel: encodeURIComponent(this.phone_num),
                suggestion: this.experience,
                star: this.starValue,
                tname: this.shared_tname
            }
            this.addSurveyData(survey_data);
        },
        changeStar(num) {
            num = num || 0;
            this.starValue = num;

            for (var i = 0; i < this.starAry.length; i++) {
                this.starAry[i].show = false;
            }

            for (var j = 0; j < this.starValue; j++) {
                this.starAry[j].show = true;
            }

        },
        clearReviewData(){
            this.starValue = 0;
            this.experience = '';
            this.starAry = [
                { show: false, value: 1 },
                { show: false, value: 2 },
                { show: false, value: 3 },
                { show: false, value: 4 },
                { show: false, value: 5 }
            ];
        },
        addSurveyData(obj) {
            let query = '?sid=' + obj.sid + '&oid=' + obj.oid + '&star=' + obj.star + '&suggestion=' + obj.suggestion + '&tel=' + obj.tel + '&tname=' + obj.tname;
            let aj_host = this.$store.state.apiHost;
            let firstUrl = aj_host + '/weapp/user/survey/add' + query;
            return fetch(firstUrl, {
                method: 'GET'
            })
                .then((res) => {
                    return res.text();
                })
                .then((res) => {
                    res = JSON.parse(res);
                    if (res.data.code === 100000) {
                        console.log('success');
                        this.hide();
                        this.set_msg({
                            msg: this.$t('footer_buttons.thanksFeedback')
                        });
                    }
                });
        },
    }
};
</script>

<style lang="scss" scoped>
.review_layer {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.6);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.review_layer_copy {
    width: 100%;
    height: 100%;
    position: absolute;
}
.review_layer .review_inner {
    background-color: #fff;
    padding: 20px 16px;
    border: 2px solid #FDD25E;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    position: relative;
    width: 80%;
    max-width: 320px;
    color: #000;
    text-align: left;
    box-sizing: border-box;
}
.review_msg {
    position: absolute;
    width: 98%;
    height: 60px;
    left: 1%;
    top: 120px;
    background-color: #fff;
    border: 1px solid #FF3B30;
    border-radius: 8px;
    box-sizing: border-box;
    color: #000;
    font-size: 16px;
    text-align: center;
    line-height: 58px;
}
.review_msg span {
    display: inline-block;
    padding-left: 24px;
    background: url('../assets/images/warn.svg') no-repeat;
    background-size: 16px auto;
    background-position: left center;
}
.review_layer .review_title {
    font-size: 16px;
    text-align: center;
}
.review_star {
    width: 180px;
    margin: 13px auto 20px;
    display: flex;
    justify-content: space-between;
}
.review_star label {
    display: none;
}
.star_radio {
    position: relative;
}
.star_radio input {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
}
.star_radio .radio_icon {
    display: block;
    width: 26px;
    height: 26px;
    mask: url('../assets/images/star.svg') no-repeat;
    background-size: contain;
    background-color: #d8d8d8;
    background-repeat: no-repeat;
    mask-size: 100% auto;
    mask-position: left center;
    cursor: pointer;
}
.star_radio .radio_icon.radio_curr {
    background-color: #fc0;
}

.review_input textarea {
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgba(116,116,128, 0.08);
    font-size: 14px;
    padding: 9px 14px;
    box-sizing: border-box;
    outline: none;
}
.review_btn {
    text-align: center;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
}
.review_btn button {
    display: block;
    width: 118px;
    height: 33px;
    border-radius: 8px;
    border: 1px solid #FDD25E;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    outline: none;
}
.review_btn button.submit_btn {
    background-color: #FDD25E;
}
</style>
