<template>
    <div class="food_layer">
        <div class="container">
            <div class="container-inner pt-4">
                <div class="p-image" v-if="detail_data.fpic">
                    <img class="img-fluid" :src="detail_data.fpic" alt="" title="" />
                    <div class="cart-item fixed">{{$t('food.total_added')}} {{ fcount }}</div>
                </div>
                <!-- .p-image -->
                <div class="p-details">
                    <div class="p-item border-b">
                        <div class="row">
                            <div class="col-8 col-xl-10">
                                <h1>
                                    {{ translate('item', detail_data.fname) }}
                                </h1>
                            </div>

                            <div class="col-4 col-xl-2">
                                <h2 class="price" :class="{ price_tbd: detail_data.is_tbd }">
                                    <div class="tbd" v-if="detail_data.is_tbd">TBD</div>
                                    <template v-else>
                                        {{ detail_data.fprice }}
                                    </template>
                                </h2>
                            </div>
                        </div>
                        <p class="pt-3 pr-5 text-break" v-html="translate('food_desc', detail_data.fdesc)">
                        </p>
                        <div v-if="detail_data.feature_name || detail_data.ofeature_name" class="my-2">
                            <div class="d-flex justify-content-between">
                                <h1>{{ $t('cart.taste_option') }}</h1>
                            </div>
                            <div class="mt-2">
                                {{ translate('feature_value',  detail_data.feature_name || detail_data.ofeature_name ) }}
                            </div>
                        </div>
                        <div v-if="detail_data.addon_names">
                            <h1>{{ $t('cart.addon_side') }}</h1>
                            <div
                                v-for="(addon_item, addon_index) in detail_data.addon_names.split('@@')"
                                :key="addon_index"
                            >
                                <div class="mt-2">
                                    <div class="row" v-for="(item_name, index) in addon_item.split('|')" :key="index">
                                        <div class="col-6">{{  translate('addon_value', item_name) }}</div>
                                        <div class="col-4">
                                            ${{ detail_data.addon_prices.split('@@')[addon_index].split('|')[index] }} x
                                            {{ getAddonItemCount(addon_index, index) }}
                                        </div>
                                        <div class="col-2">${{ getAddonItemSumPrice(addon_index, index) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="detail_data.note !== ''" class="border-b notes">
                            <div class="ntitle">{{ $t('food.note') }}</div>
                            <div class="note mt-2">{{ note }}</div>
                        </div>
                    </div>

                    <!-- .notes -->

                    <div class="quantity" v-if="from !== 'order'">
                        <div class="qaction">
                            <button class="mt-prev" @click="change_food_count('minus')">-</button>
                            <span>{{ choose_count }}</span>
                            <button class="mt-next" @click="change_food_count('add')">+</button>
                        </div>
                    </div>
                    <!-- .quantity -->
                </div>
                <!-- .p-details -->
            </div>
            <div class="menu-footer">
                <div class="row" v-if="from !== 'order'" @click="do_save" data-bee="module_id=button_click&item_id=add_x_toCart">
                    <div class="col-8">
                        <div class="card-val">
                            {{
                                choose_count > 0
                                    ? $t('cart.change_count', {count: choose_count})
                                    : $t('cart.remove_food')
                            }}
                        </div>
                    </div>

                    <div class="col-4 text-right">
                        <div class="t-price">
                            $<span class="pval">{{ total_price }}</span>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="from === 'order'">
                    <div class="col-8">
                        <div class="card-val">
                            <span>{{ $t('cart.total', {count: choose_count}) }}</span>
                        </div>
                    </div>

                    <div class="col-4 text-right">
                        <input class="per-item-price" type="hidden" value="35" />
                        <div class="t-price">
                            $<span class="pval">{{ total_price }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layer_close" @click="close_layer"></div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import validates from '@/service/validates.js';

export default {
    name: 'FoodDetailLayerCart',
    props: ['detail_data', 'close_fun', 'fcount', 'from', 'is_vip'],
    data() {
        return {
            choose_count: this.fcount,
            pic_style: 'background-image: url(' + this.detail_data.fpic + ');',
            addon_dict: {},
            addon_chosen_count: 0,
            addon_total_price: 0,
            feature_name: this.detail_data.feature_default_value || '',
            note: '',
            oid: this.$store.state.app.shared_oid || ''
        };
    },
    mounted() {
        this.note = this.detail_data.note;

        if (this.oid) {
            this.loadOrderData({
                oid: this.oid.split('-')[0],
                cartid: this.$store.state.app.shared_cart_id,
                router: this.$router
            });
        }
    },
    computed: {
        ...mapGetters({
            translate: 'app/translate'
        }),
        total_price() {
            let extra_price = 0;
            if (this.detail_data.addon_prices) {
                const addon_prices = this.detail_data.addon_prices.split('@@');
                const addon_counts = this.detail_data.addon_counts.split('@@');

                for (let i = 0, len = addon_prices.length; i < len; i++) {
                    const items = addon_prices[i].split('|');
                    const prices = addon_counts[i].split('|');
                    for (let j = 0; j < items.length; j++) {
                        extra_price = extra_price + Number(items[j]) * Number(prices[j]);
                    }
                }
            }

            if (this.choose_count > 0) {
                let _price =
                    this.is_vip && this.detail_data.fprice_vip ? this.detail_data.fprice_vip : this.detail_data.fprice;
                return ((Number(_price) + extra_price) * this.choose_count).toFixed(2);
            }
            return 0;
        }
    },
    methods: {
        ...mapActions({
            set_msg: 'app/setToast',
            edit_cart_food_count: 'app/edit_cart_food_count',
            loadOrderData: 'sharedorderdetail/initData',
            loadMenuFood: 'menu/initData'
        }),
        ...mapMutations({
            close_detail: 'app/APP_TOGGLE_CART_DETAIL'
        }),
        getAddonItemCount(addonIndex, itemIndex) {
            return this.detail_data.addon_counts.split('@@')[addonIndex].split('|')[itemIndex];
        },
        getAddonItemSumPrice(addonIndex, itemIndex) {
            const unitPrice = this.detail_data.addon_prices.split('@@')[addonIndex].split('|')[itemIndex];
            const price = this.getAddonItemCount(addonIndex, itemIndex) * unitPrice;
            return price.toFixed(2);
        },
        async do_save() {
            if (this.from === 'order') {
                return;
            }
            if (this.detail_data.is_out_of_stock) {
                return;
            }

            let _food_data = JSON.parse(JSON.stringify(this.detail_data));
            _food_data.fcount = this.choose_count;

            /*
             * 购物车中的编辑是直接编辑整体的数量，所以此处传递的应该是差值
             * changed by @wudi at 2021-09-21
             */
            await validates.checkDailyLimit({
                fid: _food_data.fid,
                that: this,
                count: this.choose_count - this.fcount
            });
            await validates.checkOnceLimit({ fid: _food_data.fid, that: this, count: this.choose_count - this.fcount });
            await validates.checkOnceSubmitLimit({ fid: _food_data.fid, that: this, count: this.choose_count - this.fcount });

            let save_data = {
                cartid: this.$store.state.app.shared_cart_id,
                tid: this.$store.state.app.shared_tid,
                uid: this.$store.state.app.uid,
                uname: _food_data.uname,
                router: this.$router,
                type: 'set',
                is_vip: this.is_vip,
                fid: _food_data.fid,
                fname: _food_data.fname,
                fprice: _food_data.fprice,
                fprice_vip: _food_data.fprice_vip || '',
                feature_name: _food_data.feature_name,
                addon_names: _food_data.addon_names,
                addon_prices: _food_data.addon_prices,
                total_price: this.total_price,
                set_count: this.choose_count,
                note: this.note,
                addon_counts: _food_data.addon_counts
            };

            this.edit_cart_food_count(save_data);
        },
        change_food_count(type) {
            if (this.from === 'order') {
                return;
            }
            if (type === 'add') {
                this.choose_count = this.choose_count + 1;
            } else if (type === 'minus' && this.choose_count > 0) {
                this.choose_count = this.choose_count - 1;
            }
        },
        close_layer(e) {
            this.close_fun();
        }
    }
};
</script>
<style lang="scss" scoped>
.layer_close {
    background-color: rgba(0, 0, 0, 0.08);
    color: #42526e;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;
    overflow: hidden;
    padding: 4px;
    margin: 4px;
    width: 32px;
    z-index: 2;
    transition: background-color 85ms, color 85ms;
    font-size: 20px;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    -align: bottom;

    &:before {
        content: 'x';
        font-weight: bolder;
    }
}
.food_layer {
    transition: opacity 300ms ease 0s;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 11;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 100px;
}
</style>
